<template>
  <div>
  </div>
</template>
<script>
export default {
  name: 'app',
  data (){
    return {}
  },
  created(){},
  mounted(){},
	methods:{},
}
</script>

<style lang="scss">
</style>
